<template>
<div id="lssj_table" v-if="record" >
<!-- <div id="range">{{be.bt}} 至 {{be.et}}</div> -->
<br>

<div class="tb" 
    v-for="dt in record.data" 
    v-bind:key="dt.ym"
    v-loading="webReqID"
    element-loading-text="正在获取中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
>
<el-table 
      :data="dt_rows_slice(dt)"
      style="width: 100%"
      border

      >
      

      <el-table-column 
        prop=0
        :label="dt_ym_label(dt)"
				:formatter="strToDate"
        fixed
        width="110rem"
      >
      </el-table-column>
      
      <el-table-column v-for="(item,key) in items" v-bind:key="item.ifixLabel"
        :prop="key + 1 + ''"
        :label="item.desc + '(' + item.unit + ')'"
      >
      </el-table-column>
    </el-table>
    <el-pagination
    background
    layout="total, sizes, prev, pager, next, jumper"
    :page-sizes="[20,50, 100, 150, 300]"
    :total="dt.count"
		@current-change="dt.page_cur_change"
    @size-change="dt.page_size_change"
    :pager-count="pagerCount"
    >
    </el-pagination>
</div>

</div>
</template>


<style scoped>


#range{
  margin:20px;
  font-size:1.2em;
}
.el-pagination{
  width:100%;
  margin:0px 0;
}

.el-pagination >>> *{
margin-bottom:10px;
}


@media screen and (max-width: 800px) {

.el-pagination >>> *{

float:left;

}
.el-pagination >>> .btn-prev{
    clear: left;
}

.el-pagination >>> .el-pagination__jump{
float:left;
width:100%;
margin:0px;
text-align:left;
}

.el-pagination >>> .el-pagination__jump *{
float:none;
}

.el-table >>> tr td:first-child .cell{
padding:0px 0px;

}

}

.el-table >>> table,
.el-table >>> th,
.el-table >>> tr,
.el-table >>> td {


border-collapse: collapse;
border: 1px solid #000;


}

.el-table >>> tr:hover>td{
background-color:#FFF68F;

}

.el-table >>> table{
color:#000000;
}

.el-table >>> th{
color:#303030;
background-color:#A4D3EE;
padding:0;
}


.el-table >>> td{
padding:4px;
}





.el-table >>> tr:nth-of-type(even){

background-color:#CAE1FF;

}
.el-table >>> tr:nth-of-type(odd){

}

</style>


<script>


//import { Table,TableColumn } from  'element-ui'
//import {Pagination} from  'element-ui'
import dateFormat from '@/commonJS/dateFormat.js'
/*import {Loading} from  'element-ui'*/
//import ElementUI from 'element-ui';

export default {
    name:'lssj_table',
    components:{
      //[Table.name]:Table,
      //[TableColumn.name]:TableColumn,
      //[Pagination.name]:Pagination,

    },
    props:['record','webReqID','cur_s_table'],
    data(){
      return {
        tbIndex:0,
        items:"",
      }
    },
    computed:{
        be:function(){
            if (this.record){
              return {
                bt: dateFormat.strToFormat(this.record.bt),
                et: dateFormat.strToFormat(this.record.et),
              }
            } 
          },
           pagerCount:function(){
                        if (this.$deviceType == 'pc'){
                              return 15

                        }else if (this.$deviceType=='mobile'){
                              return 5

                        }
            
    
           }
      
    },
    watch:{
      record(val){
        let vm = this
        for (let dt of val.data){
          //console.log(this)
          //console.log("cur_s_table",this.cur_s_table)
          this.items = this.cur_s_table
          dt.currentPage = 1
          dt.pagesize = 20

       dt.page_cur_change=function(currentPage){
        dt.currentPage = currentPage
        //vm.$set(dt,'currentPage',currentPage)
        vm.$forceUpdate()
      }
    
       dt.page_size_change=function(pagesize){
        dt.pagesize = pagesize
        vm.$forceUpdate()
        //vm.$set(dt,'pagesize',pagesize)
      }

        }
        }
      },
      
    methods:{
      strToDate(row,col,value){
         let d = value.substr(0,2)
         let h = value.substr(2,2)
         let m = value.substr(4,2)
         let s = value.substr(6,2)
        return d + "日" + h + ":" + m + ":" + s 
      },
      dt_rows_slice(dt){
        if (dt.rows.length){
          let arr =  dt.rows.slice((dt.currentPage-1)*dt.pagesize,dt.currentPage*dt.pagesize)
          
          //console.log("arr",arr)
          return arr
        }
        else{
          return [] 
        }
    
      },
      dt_ym_label(dt){
        if (dt.ym*1>0)
        return dt.ym.substr(0,4) + '年' + dt.ym.substr(4,2) + '月'
        else 
        return ""
        
      },
      },




}


</script>
