<template>
  <div id="v_table">
    <select_pc
      v-if="this.$deviceType == 'pc'"
      :tables="this.tables"
      :allLabel="this.allLabel"
      ref="select"
      :webReqID="webReqID"
    />
    <select_mb
      v-else-if="this.$deviceType == 'mobile'"
      :tables="this.tables"
      :allLabel="this.allLabel"
      ref="select"
    />
    <lssj_table
      id="lssj_table"
      :record="record"
      :webReqID="webReqID"
      :cur_s_table="cur_s_table"
    />
  </div>
</template>

<style scoped>
@media screen and (max-width: 800px) {
  #lssj_table {
    width: 100%;
  }
}
</style>

<script>
import fn_tb from "../commonJS/tableJs.js";
import dateFormat from "../commonJS/dateFormat.js";

import lssj_table from "../components/lssj_table.vue";
//import select_pc from "../components/select_pc.vue";
//import select_mb from "../components/select_mb.vue";
const select_pc = () => import(/* webpackChunkName: "select_pc" */ '../components/select_pc.vue')
const select_mb = () => import(/* webpackChunkName: "select_mb" */ '../components/select_mb.vue')

export default {
  name: "Table",
  components: {
    lssj_table,
    select_pc,
    select_mb,
  },

  data() {
    return {
      record: false,
      tables: "",
      allLabel: "",
      //s_tables:[],
      webReqID: "",
      cur_s_table: [],
    };
  },
  methods: {
    update_record: async function(prs) {
      let req = { webReqID: new Date().valueOf() };
      req.prs = prs;
      this.webReqID = req.webReqID;
      if (this.record) {
        this.record.data = [{ rows: [], ym: "" }];
      }
      this.$parent.$parent.$parent.$parent.tip = "";
      let rc = await this.get_record(req);
      if (this.webReqID == rc.webReqID) {
        if (rc.err=='timeout_err'){
        this.$parent.$parent.$parent.$parent.tip += '获取超时，请缩小查询范围' + "\n\r  ";
        this.$refs.select.notifySpan='获取超时，请缩小查询范围', 
        this.$refs.select.notifyColor='#FF0000'
        }
        else if (rc.err =='socketDisconnect'){
        this.$parent.$parent.$parent.$parent.tip += 'ifix端断连' + "\n\r  ";
        this.$refs.select.notifySpan='ifix端断连', 
        this.$refs.select.notifyColor='#FF0000'
        }
        else{
        this.$refs.select.notifyshow = false 
        }
        console.log("得到数据");
      console.log("update_record:", rc);
      for (let rc_arr of rc.data){
        if (!rc_arr.rows) {
          rc_arr = [{ rows: [], ym: "000000" }];
        }
      }
        

        this.record = rc;
        this.webReqID = "";
        if (this.record.err.length) {
          for (let e of this.record.err) {
            this.$parent.$parent.$parent.$parent.tip += e + "  ";
          }
        }
      }
    },
    get_record: async function(req) {
      let bt = dateFormat.dateToStr("YYYYmmddHHMMSS", new Date(req.prs.bt));
      let et = dateFormat.dateToStr("YYYYmmddHHMMSS", new Date(req.prs.et));
      //console.log(bt);
      //console.log(et);

      let h = req.prs.s_inter.getHours();
      let m = req.prs.s_inter.getMinutes();
      let s = req.prs.s_inter.getSeconds();
      let inter = (h * 60 * 60 + m * 60 + s) * 1000;
      let fields = fn_tb.returnFields(req.prs.cur_s_table);

      let req_r = {
        lc: this.$route.params.lc,
        bt,
        et,
        inter,
        fields,
        type:req.prs.type,
        filte:req.prs.filte,
        webReqID: req.webReqID,
      };
      console.log("req_record:", req_r);
      let rt = await fn_tb.get_record(req_r);
      this.cur_s_table = req.prs.cur_s_table;
      return rt;
    },

    get_table: async function() {
      let rt = await fn_tb.get_table({ lc: this.$route.params.lc });
      return rt;
    },
    get_label: async function() {
      let rt = await fn_tb.get_label({ lc: this.$route.params.lc });
      return rt;
    },
  },
  computed: {},
  async created() {
    this.tables = await this.get_table()
    this.allLabel = await this.get_label()
    fn_tb.data_init(this.tables,this.allLabel);

    //Promise.all([this.get_table(), this.get_label()]).then((res) => {
      //let rt = fn_tb.data_init(res[0], res[1]);
      //this.tables = res[0];
      //this.allLabel = res[1];
      //this.$set(this.allLabel, "name", "所有");
    //});
  },
};
</script>
