import axios from 'axios'

function get_record(req){
  //req.webReqID = new Date().valueOf()
  return new Promise((resolve)=>{
axios.get('/api/get/record/',{params:req})
      .then((res)=>{
        req.data = res.data.data
        req.err = res.data.err
        resolve(req)
      })
  })
}

function get_label(req){
return new Promise((resolve)=>{
axios.get('/api/get/label/',{params:req})
      .then((res)=>{
        resolve(res.data)
      })
})
}


function get_table(req){
return new Promise((resolve)=>{
axios.get('/api/get/table/',{params:req})
      .then((res)=>{
        resolve(res.data)
      })


})
}

function data_init(tables,label){
          //label.name = "所有"
          label.items = []
          label.label = []
          let key = 1
          for (let i=0,len=label.rows.length;i<len;i++){
              let obj = {}
              
              for (let j=0,len=label.fields.length;j<len;j++){
              //obj[label.fields[j]] = label.rows[1][1]
              if (label.fields[j] == "ifixLabel"){
                      label.label.push(label.rows[i][j]) 
              }
              obj[label.fields[j]] = label.rows[i][j]
              //obj[label.fields[j]].text = label.rows[i][j].desc
              obj.text = obj.desc
              }
              label.items.push(obj)
          }
          //label.label = JSON.stringify(label.label)
          label.key = 0
            
          for (let tbls of tables){
              tbls.label = JSON.parse(tbls.label)
              //tbls.fields = JSON.parse(tbls.label)
              tbls.items = []
              for (let ifixLabel of tbls.label){
                    for (let item of label.items){
                        if (item.ifixLabel == ifixLabel){
                                item.text = item.desc
                                tbls.items.push(item)
                                break
                         }
                   }
              }
              tbls.key = key++
          }

          console.log("tables:",tables)
          console.log("label:",label)
          //label.name = "所有"
          return [tables,label]
          //this.tables = tables
          //this.allLabel = label


}

function returnFields(arr){
let n_arr = []
  for (let v of  arr){
      n_arr.push(v.ifixLabel)
    }
  return n_arr
}

export default{
get_record,
get_label,
get_table,
data_init,
returnFields,

}

